<template>
    <b-card v-if="item">
        <div v-if="!auditTrial">
            <b-row class="col-md-12 mb-1 pr-0">
                <div class="col-md-6">
                    <h3 class="font-weight-bolder">
                        <b-link class="btn btn-outline-primary" to="/invoices">
                            <FeatherIcon icon="ChevronLeftIcon"></FeatherIcon>
                            {{ $t("back") }}
                        </b-link>
                        {{ $t("invoice_id") }} : I-{{ item.id }}
                    </h3>
                </div>
                <!--<div align="end" class="col-md-6 mr-0 pr-0" v-if="item.status != '1' && item.status != '8'">-->
                    <!--<InvoiceStatus :invoice="item" :status="item.status"/>-->
                <!--</div>-->
            </b-row>

            <!--<b-row class="ml-1 mr-1" v-if="item.force_update && item.force_update.length > 0">-->
            <!--<b-col class="alert alert-primary p-2" v-for="(forceUpdate, index) in item.force_update" :key="index"-->
            <!--v-if="forceUpdate.accepted == 0">-->
            <!--<b-row class="m-0">-->
            <!--<b-col>-->
            <!--<h4 class="text-primary">-->
            <!--<b-avatar variant="light-primary" size="45">-->
            <!--<feather-icon size="21" icon="AlertTriangleIcon" />-->
            <!--</b-avatar>-->
            <!--<span class="mb-25 font-weight-bolder font-lg"-->
            <!--v-if="forceUpdate.recourse == 1 && forceUpdate.silent == 0">-->
            <!--{{ $t("this_invoice_must_be_recourse_and_open_factoring") }}-->
            <!--</span>-->
            <!--<span class="mb-25 font-weight-bolder font-lg" v-else-if="forceUpdate.recourse == 1 && forceUpdate.silent == 1-->
            <!--">-->
            <!--{{ $t("this_invoice_must_be_recourse_and_silent_factoring") }}-->
            <!--</span>-->
            <!--<span class="mb-25 font-weight-bolder font-lg" v-else-if="forceUpdate.recourse == 0 && forceUpdate.silent == 0-->
            <!--">-->
            <!--{{-->
            <!--$t("this_invoice_must_be_non_recourse_and_open_factoring")-->
            <!--}}-->
            <!--</span>-->
            <!--<span class="mb-25 font-weight-bolder font-lg" v-else-if="forceUpdate.recourse == 0 && forceUpdate.silent == 1-->
            <!--">-->
            <!--{{-->
            <!--$t("this_invoice_must_be_non_recourse_and_silent_factoring")-->
            <!--}}-->
            <!--</span>-->
            <!--</h4>-->
            <!--</b-col>-->
            <!--<b-col class="text-right">-->
            <!--<b-button variant="danger" size="md" class="mr-1" @click="declineForceUpdate">{{ $t("decline") }}-->
            <!--</b-button>-->
            <!--<b-button variant="success" size="md" @click="acceptForceUpdate">{{ $t("accept") }}-->
            <!--</b-button>-->
            <!--</b-col>-->
            <!--</b-row>-->
            <!--</b-col>-->
            <!--</b-row>-->
            <b-row class="ml-1 mr-1" v-if="global_settings && global_settings[0].status">
                <b-col class="alert alert-success p-2 text-black-50" v-if="">
                    <teplate >
                        <p class="text-dark" style="font-size: 16px;">{{ $t('wait_offer_message') }}</p>
                    </teplate>
                </b-col>
            </b-row>
            <b-row class="ml-1 mr-1" v-if="global_settings && !global_settings[0].status && item.status == 2 && item.msg != null">
                <b-col :class="item.status == 2 ? 'alert alert-danger' : 'alert alert-secondary'" class="p-2 col-md-12">
                    <h4>
                        <b-avatar :variant="item.status == 2 ? 'light-danger' : 'light-secondary'" class="ml-1 mr-1" size="45">
                            <feather-icon icon="AlertTriangleIcon" size="21"/>
                        </b-avatar>
                        <span :class="item.status == 2 ? 'text-danger' : 'text-secondary'" class="font-lg" v-if="item.msg">{{$t(item.msg) }}</span>
                    </h4>
                </b-col>
            </b-row>
            <!--<b-row class="ml-1 mr-1 mt-1" v-if="item.status == 8">-->
            <!--<b-col class="alert alert-primary p-2 col-md-12">-->
            <!--<h4>-->
            <!--<b-avatar variant="light-primary" size="45" class="ml-1">-->
            <!--<feather-icon size="21" icon="AlertTriangleIcon" />-->
            <!--</b-avatar>-->
            <!--<span class="font-lg text-primary">{{-->
            <!--$t("soon_goes_active")-->
            <!--}}</span>-->
            <!--</h4>-->
            <!--</b-col>-->
            <!--</b-row>-->

            <b-row class="ml-1 mr-1" v-if="item && item.scorings && item.scorings.length > 0 && item.status != 2">
                <b-col class="alert alert-success p-2 text-black-50">
                    <template v-if="global_settings && global_settings[0].status && item.status != 0">
                        <p class="text-dark" style="font-size: 16px;">{{ $t('wait_offer_message') }}</p>
                    </template>
                    <template v-else>
                        <b-row class="m-0" v-if="item.status == 1">
                            <div align="start" class="col-md-6 mr-0 pr-0">
                                <InvoiceStatus :invoice="item" :status="item.status"/>
                            </div>
                        </b-row>
                        <b-row class="m-0 col-md-12">
                            <b-col class="font-weight-bolder text-black-50 p-1" md="4">
                                <h5>
                                    {{ $t("rating") }}
                                    <feather-icon icon="HelpCircleIcon" size="18"/>
                                    <br/>
                                    {{ item && item.scorings ? item.scorings[0].rating : "" }}
                                </h5>
                            </b-col>
                            <b-col class="font-weight-bolder text-black-50 p-1" md="4">
                                <h5>
                                    {{ item.is_reverse == 1 ? $t("surcharge_in_%") : $t("discount_in_%") }}
                                    <feather-icon icon="HelpCircleIcon" size="18"/>
                                    <br/>
                                    {{
                                    item && item ? item.scorings[0].discount_in_percentage.scorings

                                    : ""
                                    }}
                                    %
                                </h5>
                            </b-col>
                            <b-col class="font-weight-bolder text-black-50 p-1" md="4">
                                <h5>
                                    {{ $t("type") }}
                                    <feather-icon icon="HelpCircleIcon" size="18"/>
                                    <br/>
                                    <span>{{ item.recourse == 1 ? $t("Recourse") : $t("Non-Recourse") }} </span>
                                </h5>
                            </b-col>

                            <b-col class="font-weight-bold text-black-50 p-1" md="4">
                                <h5>
                                    {{ $t("effective_value") }}
                                    <feather-icon icon="HelpCircleIcon" size="18"/>
                                    <br/>
                                    {{ item.currency.code }}
                                    {{
                                    item && item.scorings
                                    ? item.scorings[0].amount_with_discount
                                    : 0
                                    }}
                                </h5>
                            </b-col>
                            <b-col class="font-weight-bold text-black-50 p-1" md="4">
                                <h5>
                                    {{ item.is_reverse == 1 ? $t("surcharge") : $t("discount") }}
                                    <feather-icon icon="HelpCircleIcon" size="18"/>
                                    <br/>
                                    {{ item.currency.code }}
                                    {{
                                    item && item.scorings
                                    ? item.scorings[0].discount_from_amount
                                    : 0
                                    }}
                                </h5>
                            </b-col>
                            <b-col class="font-weight-bold text-black-50 p-1" md="4">
                                <h5>
                                    {{ item.recourse == 2 ? $t("confirmation_by_supplier") :
                                    $t("confirmation_by_debtor") }}
                                    <feather-icon icon="HelpCircleIcon" size="18"/>
                                    <br/>
                                    <span v-if="item.debtor_confirmed == 1">{{ $t("confirmed") }}</span>
                                    <span v-else-if="item.recourse == 1"> {{ $t("not_needed") }}</span>
                                    <span v-else> {{ $t("required") }}</span>
                                </h5>
                            </b-col>
                        </b-row>
                    </template>
                </b-col>
            </b-row>

            <validation-observer ref="formValidate">
                <b-form @submit.prevent>
                    <div class="col-md-12 mt-4">
                        <b-row m-0 p-0 v-if="!load">
                            <b-col class="mt-2 pl-0" lg="12" md="12" sm="12" xl="12">
                                <b-form-group class="d-flex" v-slot="{ ariaDescribedby }">
                                    <validation-provider #default="{ errors }" :name="$t('recourse')" rules="required">
                                        <div class="col-12 d-flex">
                                            <b-form-radio :aria-describedby="ariaDescribedby" :title="$t(options[0].title)" @blur="editInvoice()"
                                                          class="mr-2" id="nonRecourseTooltip" v-b-tooltip.hover v-model="item.recourse"
                                                          value="0">
                                                {{ $t("Non-recourse") }}
                                            </b-form-radio>
                                            <b-form-radio :aria-describedby="ariaDescribedby" :title="$t(options[1].title)" @blur="editInvoice()"
                                                          class="mr-2" id="recourseTooltip" v-b-tooltip.hover v-model="item.recourse"
                                                          value="1">
                                                {{ $t("Recourse") }}
                                                <small>{{ $t("recourse_text") }}</small>
                                            </b-form-radio>
                                            <b-form-radio :aria-describedby="ariaDescribedby" :title="$t(options[2].title)"
                                                          @blur="editInvoice()" class="mr-2" id="reverseTooltip" v-model="item.recourse"
                                                          value="2">
                                                {{ $t("Reverse") }}
                                            </b-form-radio>
                                        </div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col class="" lg="8" md="8" sm="12" xl="8">
                                <b-form-group :label="(item.recourse == 2 ? $t('supplier') : $t('deptor')) + ':'" class="font-weight-bolder" id="deptor" label-for="deptor"
                                              v-if="item.company_name == '' || !item.issuer_id">
                                    <validation-provider #default="{ errors }" :name="item.recourse == 2 ? $t('supplier') : $t('deptor')" rules="required">
                                        <vue-autosuggest :disabled="item && item.scorings && item.scorings.length > 0
                        " :input-props="{
    id: 'autosuggest__input',
    class: 'form-control',
  }" :limit="10"
                                                         :suggestions="filteredOptions" @keyup="searchForCompaniesName(item)" @selected="onSelected($event, item, index)" v-model.trim="item.company_name">
                                            <template slot-scope="{ suggestion }">
                        <span class="my-suggestion-item">{{ suggestion.item }}
                        </span>
                                            </template>
                                        </vue-autosuggest>
                                        <b-button class="mt-1" disabled v-if="showHint" variant="primary">
                                            <b-spinner small type="grow"></b-spinner>
                                            {{ $t("loading") }}...
                                        </b-button>
                                        <div class="alert alert-secondary p-1 mt-1" v-if="companyMessage">
                                            <span v-if="$store.state.app.language == 'en'"> If you can not find the compnay of your {{ item.recourse == 2 ? 'supplier' : 'debtor' }} on the list please contact administrator at  <strong>support@aequitex.com</strong> or you can <a @click="openLink()" class="font-weight-bolder" style="text-decoration:underline;">book a meeting here!</a></span>

                                            <span v-else-if="$store.state.app.language == 'de'">
                      Falls Sie die Firma Ihres {{ item.is_reverse == 1 ? 'Anbieter' : 'Schuldners' }} in dieser Liste nicht finden können, kontaktieren Sie bitte den Administrator über <strong>support@aequitex.com</strong>  oder <a @click="openLink()" class="font-weight-bolder" style="text-decoration:underline;">buchen Sie sich hier Ihr persönliches Video-Meeting</a>.
                     </span>

                                        </div>
                                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                                    </validation-provider>
                                </b-form-group>
                                <b-form-group :label="(item.recourse == 2 ? $t('supplier') : $t('deptor')) + ':'" class="font-weight-bolder" id="deptor" label-for="deptor"
                                              v-else>
                                    <validation-provider #default="{ errors }" :name="(item.recourse == 2 ? $t('supplier') : $t('deptor'))" rules="required">
                                        <b-form-input disabled v-model.trim="item.debtor.name"></b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                                <img height="50" src="/new-loader.svg" v-if="loader" width="50"/>
                            </b-col>

                            <b-col lg="4" md="4" sm="12" xl="4">
                                <b-form-group
                                    :label="$t('currency') + ':'"
                                    clasa="d-flex flex-direction-column"
                                    class="font-weight-bolder"
                                    id="currency"
                                    label-for="currency"
                                >
                                    <b-dropdown :disabled="item && item.currency_id != 1" class="w-50 no-arrow" id="currency-dropdown" variant="outline-dark">

                                        <template #button-content>
                                            <!--<template v-if="item && item.currency">-->
                                            <!--<span v-if="currency_name">{{ currency_name }}</span>-->
                                            <!--<span v-else>{{item.currency.name}}</span>-->
                                            <!--</template>-->
                                            <!--<template v-else>-->
                                            <!--<span v-if="currency_name">{{ currency_name }}</span>-->
                                            <!--<span v-else>{{ $t('select_currency') }}</span>-->
                                            <!--</template>-->
                                            <span>CHF</span>
                                        </template>

                                        <b-dropdown-item
                                            :key="option.id"
                                            :value="option.id"
                                            @click="changeCurrency(option.id, option.name)"
                                            v-for="option in currencies"
                                        >
                                            {{ option.name }}
                                        </b-dropdown-item>

                                    </b-dropdown>

                                    <div>
                                        <small class="text-danger" v-if="currencyError">{{ $t(currencyError) }}</small>
                                    </div>
                                </b-form-group>
                            </b-col>

                            <b-col lg="6" md="6" sm="12" xl="6">
                                <b-form-group :label="$t('invoice_amount') + ':'" class="font-weight-bolder" id="amount"
                                              label-for="amount">
                                    <validation-provider #default="{ errors }" :name="$t('invoice_amount')" rules="required">
                                        <b-form-input :disabled="item.status == '3' ||
                        (item && item.scorings && item.scorings.length > 0)
                        " :input-props="{
                        class: 'form-control',
                      }"
                                                      id="amount-input" placeholder="00'000.00" v-model.trim="item.amount"></b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="6" md="6" sm="12" xl="6">
                                <b-form-group :label="$t('issue_date') + ':'" class="font-weight-bolder" id="issue_date"
                                              label-for="issue_date">
                                    <validation-provider #default="{ errors }" :name="$t('issue_date')" rules="required">
                                        <div>
                                            <b-form-datepicker
                                                :disabled="
                          item.status == '3' ||
                          (item && item.scorings && item.scorings.length > 0)
                          "
                                                :locale="$store.state.app && $store.state.app.language == 'de'
                        ? 'de'
                        : 'en'
                      "
                                                :placeholder="$t('choose_a_date')"
                                                :state="errors.length > 0 ? false : null"
                                                @blur="editInvoice()"
                                                @input="changeTerms(item)"
                                                id="issue_date_datepicker" no-flip required v-model.trim="item.issue_date"></b-form-datepicker>
                                        </div>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="6" md="6" sm="12" xl="6">
                                <b-form-group
                                    :label="$t('payment_term_in_days')  + ' (' + $t('calculated') + ') :'"
                                    class="font-weight-bolder"
                                    disabled
                                    id="payment_date"
                                    label-for="payment_date"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="$t('payment_term_in_days')"
                                        rules="required"
                                    >
                                        <b-form-input
                                            :disabled="true"
                                            @blur="editInvoice()"
                                            id="payment_date"
                                            v-model.trim="item.terms"
                                        ></b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="errorDueDate.length > 0">{{ errorDueDate }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="6" md="6" sm="12" xl="6">
                                <b-form-group
                                    :label="$t('due_date')+ ':'"
                                    class="font-weight-bolder"
                                    id="due_date"
                                    label-for="due_date"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="due_date"
                                        rules="required"
                                    >
                                        <b-form-datepicker
                                            :disabled="
                          item.status == '3' ||
                          (item && item.scorings && item.scorings.length > 0)
                        "
                                            :locale="
                        $store.state.app && $store.state.app.language == 'de'
                          ? 'de'
                          : 'en'
                        "
                                            :placeholder="$t('choose_a_date')"
                                            @input="changeTerms(item)"
                                            id="due_date_input"
                                            no-flip
                                            required
                                            v-model.trim="item.due_date"></b-form-datepicker>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="6" md="6" sm="12" xl="6">
                                <b-form-group :label="$t('invoice_number') + ':'" class="font-weight-bolder" label-for="invoice_number">
                                    <validation-provider #default="{ errors }" :name="$t('invoice_number')" rules="required">
                                        <b-form-input :disabled="item.status == '3' ||
                      (item && item.scorings && item.scorings.length > 0)
                      " id="invoice_number" v-model.trim="item.invoice_number"></b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col sm="12" v-if="item.recourse == 2">
                                <b-row>
                                    <b-col lg="6" md="6" sm="12" xl="6">
                                        <b-form-group :label="$t('bic') + ':'" class="font-weight-bolder" label-for="bic">
                                            <validation-provider #default="{ errors }" :name="$t('bic')" rules="required">
                                                <b-form-input :disabled="companyBankData.bic !== null && (item.status == '3' ||
                      (item && item.scorings && item.scorings.length > 0))
                      " id="bic" v-model.trim="item.bic"></b-form-input>
                                                <small class="text-danger" v-if="!item.bic">{{ $t("This field is
                                                    required") }}
                                                </small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="6" md="6" sm="12" xl="6">
                                        <b-form-group :label="$t('iban') + ':'" class="font-weight-bolder" label-for="iban">
                                            <validation-provider #default="{ errors }" :name="$t('iban')" rules="required">
                                                <b-form-input :disabled="companyBankData.iban !== null && (item.status == '3' ||
                      (item && item.scorings && item.scorings.length > 0))
                      " id="iban" v-model.trim="item.iban"></b-form-input>
                                                <small class="text-danger" v-if="!item.iban">{{ $t("This field is
                                                    required") }}
                                                </small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="6" md="6" sm="12" xl="6">
                                        <b-form-group :label="$t('bank_address') + ':'" class="font-weight-bolder" label-for="bank_address">
                                            <validation-provider #default="{ errors }" :name="$t('bank_address')" rules="required">
                                                <b-form-input :disabled="companyBankData.bank_address !== null && (item.status == '3' ||
                      (item && item.scorings && item.scorings.length > 0))
                      " id="bank_address" v-model.trim="item.bank_address"></b-form-input>
                                                <small class="text-danger" v-if="!item.bank_address">{{ $t("This field
                                                    is required") }}
                                                </small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="6" md="6" sm="12" xl="6">
                                        <b-form-group :label="$t('bank_name') + ':'" class="font-weight-bolder" label-for="bank_name">
                                            <validation-provider #default="{ errors }" :name="$t('bank_name')" rules="required">
                                                <b-form-input :disabled="companyBankData.bank_name !== null && (item.status == '3' ||
                      (item && item.scorings && item.scorings.length > 0))
                      " id="bank_name" v-model.trim="item.bank_name"></b-form-input>
                                                <small class="text-danger" v-if="!item.bank_name">{{ $t("This field is
                                                    required") }}
                                                </small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>


                            <b-col class="mt-2" lg="6" md="6" sm="12" xl="6"></b-col>

                            <!-- <div class="col-12  pt-0" v-if="item.recourse == 1">
                                  <b-form-group>
                                      <b-form-checkbox v-model.trim="silent_factoring_accepted"
                                        readonly disabled >
                                        <span  v-b-tooltip.hover :title="$t('silent_factoring_tooltip')">
                                          {{ $t("silent_factoring") }}
                                        </span>
                                      </b-form-checkbox>
                                  </b-form-group>
                                </div> -->
                            <b-col class="mt-0" lg="12" md="12" sm="12" v-if="item.recourse == 1 || item.recourse == 2" xl="12">
                                <div class="col-12 alert alert-secondary p-2">
                                    <template v-if="item.recourse == 2">
                                        <div v-if="$store.state.app.language === 'en'">
                                            I hereby confirm the following agreement within the framework of reverse
                                            factoring:
                                            <br>
                                            <br>
                                            1.1 The contractual partner (debtor) transfers its liabilities from
                                            deliveries and services to the invoice purchaser (factor). This invoice
                                            purchaser (factor) shall assume these liabilities on account of performance,
                                            subject to clauses 1.2 and 1.3 of this agreement.
                                            <br>
                                            <br>
                                            1.2 This is a case of reverse factoring. This means that the contractual
                                            partner (debtor) retains the default risk for the transferred liabilities.
                                            <br>
                                            <br>
                                            1.3 The contractual partner (debtor) pays the amount of its liability to
                                            Aequitex AG. The contractual partner (debtor) transfers the full amount of
                                            his liabilities to Aequitex AG on the due date stated on the invoice.
                                            <br>
                                            <br>
                                            1.4 The contractual partner (debtor) assumes all costs arising from his
                                            non-payment, including reminder and collection costs.
                                        </div>
                                        <div v-else-if="$store.state.app.language === 'de'">
                                            Hiermit bestätige ich folgende Vereinbarung im Rahmen des
                                            Reverse-Factorings:
                                            <br>
                                            <br>
                                            1.1 Der Vertragspartner (Debtor) übergibt seine Verbindlichkeiten aus
                                            Lieferungen und Leistungen an den Rechnungskäufer (Factor) ab. Dieser
                                            Rechnungskäufer (Factor) übernimmt diese Verbindlichkeiten erfüllungshalber
                                            vorbehaltlich Ziff. 1.2 und 1.3 dieses Vertrages.
                                            <br>
                                            <br>
                                            1.2 Es handelt sich um ein Reverse Factoring. Das bedeutet, dass der
                                            Vertragspartner (Debtor) das Ausfallrisiko für die übergebenen
                                            Verbindlichkeiten behält.
                                            <br>
                                            <br>
                                            1.3 Der Vertragspartner (Debtor) begleicht den Betrag seiner Verbindlichkeit
                                            an die Aequitex AG. Der Vertragspartner (Debtor) überweist Aequitex AG den
                                            vollen Betrag seiner Verbindlichkeiten zum auf der Rechnung angegebenen
                                            Fälligkeitsdatum.
                                            <br>
                                            <br>
                                            1.4 Der Vertragspartner (Debtor) übernimmt alle Kosten, die durch seinen
                                            Zahlungsausfall entstehen, einschließlich Mahn- und Inkassokosten.
                                        </div>
                                    </template>

                                    <template v-else>
                                        <div v-if="$store.state.app.language == 'en'">
                                            I hereby confirm the following agreement within the
                                            framework of recourse factoring:
                                            <br/>
                                            <br/>
                                            1.1 The Seller assigns various of its receivables from
                                            deliveries and services to the Buyer. The Buyer accepts
                                            these receivables on account of performance subject to
                                            Clause 1.2 and § 3 of this agreement.
                                            <br/>
                                            <br/>
                                            1.2 This is a case of recourse factoring. This means that
                                            the seller assumes the default risk for the assigned
                                            receivables
                                            <br/>
                                            <br/>
                                            1.3 The debtor pays the invoice amount to the seller. The
                                            seller transfers the full invoice amount to the buyer on the
                                            due date stated on the invoice.

                                            <br/>
                                            <br/>
                                            1.4 If the debtor defaults on payment, the seller is obliged
                                            to repay the purchase price of the claim to the buyer.
                                            <br/>
                                            <br/>
                                            1.5 The seller shall bear all costs arising from the
                                            default, including dunning and collection costs.
                                        </div>
                                        <div v-else-if="$store.state.app.language == 'de'">
                                            Hiermit bestätige ich folgende Vereinbarung im Rahmen des
                                            Recourse-Factoring:

                                            <br/><br/>

                                            1.1 Der Verkäufer überträgt verschiedene seiner Forderungen
                                            aus Lieferungen und Leistungen an den Käufer ab. Der Käufer
                                            übernimmt diese Forderungen erfüllungshalber vorbehaltlich
                                            Ziff. 1.2 und § 3 dieses Vertrages.
                                            <br/>
                                            <br/>
                                            1.2 Es handelt sich um ein Recourse Factoring. Das bedeutet,
                                            dass der Verkäufer das Ausfallrisiko für die abgetretenen
                                            Forderungen übernimmt<br/>
                                            <br/>
                                            1.3 Der Schuldner begleicht den Forderungsbetrag an den
                                            Verkäufer. Der Verkäufer überweist dem Käufer den vollen
                                            Forderungsbetrag zum auf der Rechnung angegebenen
                                            Fälligkeitsdatum.
                                            <br/>
                                            <br/>
                                            1.4 Im Falle des Zahlungsausfalls des Schuldners ist der
                                            Verkäufer verpflichtet, den Kaufpreis der Forderung an den
                                            Käufer zurückzuzahlen.
                                            <br/>
                                            <br/>
                                            1.5 Der Verkäufer übernimmt alle Kosten, die durch den
                                            Zahlungsausfall entstehen, einschließlich Mahn- und
                                            Inkassokosten.
                                        </div>
                                    </template>
                                    <b-row class="pt-2">
                                        <!--<b-col md="4" class="mt-2">-->
                                        <!--<div class="pb-0 font-weight-bolder">-->
                                        <!--{{ $t("business_analysis") }}-->
                                        <!--</div>-->
                                        <!--<validation-provider #default="{ errors }" :name="$t('business_analysis')" rules="required" v-if="!item.user_uploaded_analysis.business_analysis">-->
                                        <!--<b-form-file style="display: none" @change="uploadFile($event, 'business_analysis')"-->
                                        <!--v-model.trim="business_analysis" :placeholder="$t('upload_a_file_or_drag_files_here')"-->
                                        <!--drop-placeholder="Drop file here..." ref="fileInput1"></b-form-file>-->
                                        <!--&lt;!&ndash;<small class="text-danger">{{ errors[0] }}</small>&ndash;&gt;-->
                                        <!--<div class="col-12 pl-0 pt-2 text-danger" v-if="business_analysis_req_error">{{ $t('required_field') }}</div>-->
                                        <!--</validation-provider>-->
                                        <!--<div v-else>-->
                                        <!--<b-form-file style="display: none" @change="uploadFile($event, 'business_analysis')"-->
                                        <!--v-model.trim="business_analysis" :placeholder="$t('upload_a_file_or_drag_files_here')"-->
                                        <!--drop-placeholder="Drop file here..." ref="fileInput1"></b-form-file>-->
                                        <!--</div>-->
                                        <!--<span v-if="item.user_uploaded_analysis.business_analysis">{{$t('you_already_uploaded_this_type_of_file')}}</span>-->
                                        <!--<div class="mt-1 mr-0 pr-0" v-if="!business_analysisUploaded">-->
                                        <!--<b-alert show variant="info" class="col-md-8">-->
                                        <!--<b-row class="col-md-12">-->
                                        <!--<b-col class="col-md-12 mt-2 mb-2 text-center pointer" @click="triggerFileInput">-->
                                        <!--<div>-->
                                        <!--<feather-icon icon="DownloadIcon" size="25" />-->
                                        <!--<span></span>-->
                                        <!--{{ $t("upload_a_file_or_drag_files_here") }}-->
                                        <!--</div>-->
                                        <!--</b-col>-->
                                        <!--</b-row>-->
                                        <!--</b-alert>-->
                                        <!--</div>-->

                                        <!--<div v-if="business_analysisUploaded" class="mt-1 mr-0 pr-0">-->
                                        <!--<b-alert show variant="success" class="col-md-8">-->
                                        <!--<b-row class="col-md-12">-->
                                        <!--<b-col class="col-md-12 mt-2 mb-2 text-center">-->
                                        <!--<div>-->
                                        <!--<feather-icon icon="CheckIcon" size="25"></feather-icon>-->
                                        <!--<span>-->
                                        <!--{{ $t("uploaded") }}-->
                                        <!--</span>-->
                                        <!--</div>-->
                                        <!--</b-col>-->
                                        <!--</b-row>-->
                                        <!--</b-alert>-->
                                        <!--</div>-->
                                        <!--</b-col>-->
                                        <b-col class="mt-2" md="6">
                                            <div class="pb-0 font-weight-bolder">
                                                {{ $t("annual_balance") }}
                                            </div>
                                            <validation-provider #default="{ errors }" :name="$t('annual_balance')" rules="required" v-if="!item.user_uploaded_analysis.annual_balance">
                                                <b-form-file :placeholder="$t('upload_a_file_or_drag_files_here')" @change="uploadFile($event, 'annual_balance')"
                                                             drop-placeholder="Drop file here..." ref="fileInput2"
                                                             style="display: none" v-model.trim="annual_balance"></b-form-file>
                                                <!--<small class="text-danger">{{ errors[0] }}</small>-->
                                                <div class="col-12 pl-0 pt-2 text-danger" v-if="annual_balance_req_error">
                                                    {{ $t('required_field') }}
                                                </div>
                                            </validation-provider>
                                            <div v-else>
                                                <b-form-file :placeholder="$t('upload_a_file_or_drag_files_here')" @change="uploadFile($event, 'annual_balance')"
                                                             drop-placeholder="Drop file here..." ref="fileInput2"
                                                             style="display: none" v-model.trim="annual_balance"></b-form-file>
                                            </div>
                                            <span v-if="item.user_uploaded_analysis.annual_balance">{{$t('you_already_uploaded_this_type_of_file')}}</span>
                                            <div class="mt-1 mr-0 pr-0" v-if="!annual_balanceUploaded">
                                                <b-alert class="col-md-8" show variant="info">
                                                    <b-row class="col-md-12">
                                                        <b-col @click="triggerFileInput2" class="col-md-12 mt-2 mb-2 text-center pointer">
                                                            <div>
                                                                <feather-icon icon="DownloadIcon" size="25"/>
                                                                <span></span>
                                                                {{ $t("upload_a_file_or_drag_files_here") }}
                                                            </div>
                                                        </b-col>
                                                    </b-row>
                                                </b-alert>
                                            </div>
                                            <div class="mt-1 mr-0 pr-0" v-if="annual_balanceUploaded">
                                                <b-alert class="col-md-8" show variant="success">
                                                    <b-row class="col-md-12">
                                                        <b-col class="col-md-12 mt-2 mb-2 text-center">
                                                            <div>
                                                                <feather-icon icon="CheckIcon" size="25"></feather-icon>
                                                                <span>
                                  {{ $t("uploaded") }}
                                </span>
                                                            </div>
                                                        </b-col>
                                                    </b-row>
                                                </b-alert>
                                            </div>
                                        </b-col>

                                        <b-col class="mt-2" md="6">
                                            <div class="pb-0 font-weight-bolder">
                                                {{ $t("tax_settlement") }}
                                            </div>
                                            <validation-provider #default="{ errors }" :name="$t('tax_settlement')" rules="required" v-if="!item.user_uploaded_analysis.tax_settlement">
                                                <b-form-file :placeholder="$t('upload_a_file_or_drag_files_here')" @change="uploadFile($event, 'tax_settlement')"
                                                             drop-placeholder="Drop file here..." ref="fileInput3"
                                                             style="display: none" v-model.trim="tax_settlement"></b-form-file>
                                                <!--<small class="text-danger">{{ errors[0] }}</small>-->
                                                <div class="col-12 pl-0 pt-2 text-danger" v-if="tax_settlement_req_error">
                                                    {{ $t('required_field') }}
                                                </div>
                                            </validation-provider>
                                            <div v-else>
                                                <b-form-file :placeholder="$t('upload_a_file_or_drag_files_here')" @change="uploadFile($event, 'tax_settlement')"
                                                             drop-placeholder="Drop file here..." ref="fileInput3"
                                                             style="display: none" v-model.trim="tax_settlement"></b-form-file>
                                            </div>
                                            <span v-if="item.user_uploaded_analysis.tax_settlement">{{$t('you_already_uploaded_this_type_of_file')}}</span>
                                            <div class="mt-1 mr-0 pr-0" v-if="!tax_settlementUploaded">
                                                <b-alert class="col-md-8" show variant="info">
                                                    <b-row class="col-md-12">
                                                        <b-col @click="triggerFileInput3" class="col-md-12 mt-2 mb-2 text-center pointer">
                                                            <div>
                                                                <feather-icon icon="DownloadIcon" size="25"/>
                                                                <span></span>
                                                                {{ $t("upload_a_file_or_drag_files_here") }}
                                                            </div>
                                                        </b-col>
                                                    </b-row>
                                                </b-alert>
                                            </div>
                                            <div class="mt-1 mr-0 pr-0" v-if="tax_settlementUploaded">
                                                <b-alert class="col-md-8" show variant="success">
                                                    <b-row class="col-md-12">
                                                        <b-col class="col-md-12 mt-2 mb-2 text-center">
                                                            <div>
                                                                <feather-icon icon="CheckIcon" size="25"></feather-icon>
                                                                <span>
                                  {{ $t("uploaded") }}
                                </span>
                                                            </div>
                                                        </b-col>
                                                    </b-row>
                                                </b-alert>
                                            </div>
                                        </b-col>
                                    </b-row>

                                    <div class="col-12 pl-0 pt-2">
                                        <b-form-group>
                                            <validation-provider #default="{ errors }" :name="$t('accept_recourse')" rules="required">
                                                <b-form-checkbox :disabled="item.status != 0" :state="errors.length > 0 ? false : null"
                                                                 @change="changeInvoiceFact(acceptRecourse)" required v-model.trim="acceptRecourse">
                          <span>
                            {{ $t("accept") }}
                          </span>
                                                </b-form-checkbox>
                                                <small class="text-danger">{{ $t(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </div>
                                </div>
                            </b-col>
                            <!--<b-col-->
                            <!--sm="12"-->
                            <!--xl="12"-->
                            <!--md="12"-->
                            <!--lg="12"-->
                            <!--class="mt-0"-->
                            <!--v-if="item.recourse == 2"-->
                            <!--&gt;-->
                            <!--<div class="col-12 alert alert-secondary p-2">-->
                            <!--<div v-if="$store.state.app.language === 'en'">-->
                            <!--I hereby confirm the following agreement within the framework of reverse-->
                            <!--factoring:-->
                            <!--<br>-->
                            <!--<br>-->
                            <!--1.1 The contractual partner (debtor) transfers its liabilities from deliveries-->
                            <!--and services to the invoice purchaser (factor). This invoice purchaser (factor)-->
                            <!--shall assume these liabilities on account of performance, subject to clauses 1.2-->
                            <!--and 1.3 of this agreement.-->
                            <!--<br>-->
                            <!--<br>-->
                            <!--1.2 This is a case of reverse factoring. This means that the contractual partner-->
                            <!--(debtor) retains the default risk for the transferred liabilities.-->
                            <!--<br>-->
                            <!--<br>-->
                            <!--1.3 The contractual partner (debtor) pays the amount of its liability to-->
                            <!--Aequitex AG. The contractual partner (debtor) transfers the full amount of his-->
                            <!--liabilities to Aequitex AG on the due date stated on the invoice.-->
                            <!--<br>-->
                            <!--<br>-->
                            <!--1.4 The contractual partner (debtor) assumes all costs arising from his-->
                            <!--non-payment, including reminder and collection costs.-->
                            <!--</div>-->
                            <!--<div v-else-if="$store.state.app.language === 'de'">-->
                            <!--Hiermit bestätige ich folgende Vereinbarung im Rahmen des Reverse-Factorings:-->
                            <!--<br>-->
                            <!--<br>-->
                            <!--1.1 Der Vertragspartner (Debtor) übergibt seine Verbindlichkeiten aus-->
                            <!--Lieferungen und Leistungen an den Rechnungskäufer (Factor) ab. Dieser-->
                            <!--Rechnungskäufer (Factor) übernimmt diese Verbindlichkeiten erfüllungshalber-->
                            <!--vorbehaltlich Ziff. 1.2 und 1.3 dieses Vertrages.-->
                            <!--<br>-->
                            <!--<br>-->
                            <!--1.2 Es handelt sich um ein Reverse Factoring. Das bedeutet, dass der-->
                            <!--Vertragspartner (Debtor) das Ausfallrisiko für die übergebenen Verbindlichkeiten-->
                            <!--behält.-->
                            <!--<br>-->
                            <!--<br>-->
                            <!--1.3 Der Vertragspartner (Debtor) begleicht den Betrag seiner Verbindlichkeit an-->
                            <!--die Aequitex AG. Der Vertragspartner (Debtor) überweist Aequitex AG den vollen-->
                            <!--Betrag seiner Verbindlichkeiten zum auf der Rechnung angegebenen-->
                            <!--Fälligkeitsdatum.-->
                            <!--<br>-->
                            <!--<br>-->
                            <!--1.4 Der Vertragspartner (Debtor) übernimmt alle Kosten, die durch seinen-->
                            <!--Zahlungsausfall entstehen, einschließlich Mahn- und Inkassokosten.-->
                            <!--</div>-->

                            <!--<div class="col-12 pl-0 pt-2">-->
                            <!--<b-form-group>-->
                            <!--<validation-provider-->
                            <!--#default="{ errors }"-->
                            <!--rules="required"-->
                            <!--:name="$t('accept_reverse')"-->
                            <!--&gt;-->
                            <!--<b-form-checkbox-->
                            <!--v-model.trim="acceptReverse"-->
                            <!--@change="acceptReverseChange"-->
                            <!--:state="acceptReverseError ? false : null"-->
                            <!--:disabled="item.status != 0"-->
                            <!--required-->
                            <!--&gt;-->
                            <!--<span>-->
                            <!--{{ $t("accept") }}-->
                            <!--</span>-->
                            <!--</b-form-checkbox>-->
                            <!--<small class="text-danger" v-if="acceptReverseError">{{ $t("This field-->
                            <!--is required") }}-->
                            <!--</small>-->
                            <!--</validation-provider>-->
                            <!--</b-form-group>-->
                            <!--</div>-->
                            <!--</div>-->
                            <!--</b-col>-->
                        </b-row>

                        <b-row cols="12" lg="6" md="6" v-else xl="6">
                            <img src="/new-loader.svg"/>
                        </b-row>
                    </div>

                    <b-row class="col-md-12 mt-2" v-if="false">
                        <b-col class="row blue-jumbotron row mt-1 ml-1 mr-1 pt-2 pb-2">
                            <b-col class="pb-0 mb-0" md="12">
                                <div class="text-decoration-underline">
                                    Diese AGB's gelten. Wir machen Sie insbesondere darauf
                                    aufmerksam, dass Falschangaben strafrechtlich geahndet werden
                                    können.
                                </div>

                                <ul>
                                    <li>A</li>
                                    <li>B</li>
                                    <li>C</li>
                                </ul>
                                <div class="row col-md-12">
                                    <b-checkbox v-model.trim="agreeTerms"></b-checkbox>
                                    <label for="agree">
                                        {{ $t("i_have_read_and_agree_with") }}</label>
                                </div>
                            </b-col>
                        </b-col>
                    </b-row>
                    <b-row class="col-md-12 mr-0 pr-0">
                        <b-col align="start" md="6">
                            <b-row align="start" class="mt-1 justify-content-start col-6">
                                <b-button @click="
                  changeModalInfo(
                    'remove',
                    $t('remove'),
                    $t('are_you_sure_you_want_to_remove_this_invoice')
                  )
                  " class="mr-1 mb-1" v-b-modal.approveModal v-if="item.status != 1" variant="primary">
                                    {{ $t("remove") }}
                                </b-button>
                                <!-- <b-button
                                  variant="primary"
                                  class="mr-1 mb-1"
                                  v-b-modal.approveModal
                                  @click="changeModalInfo('archive',$t('archive'),$t('are_you_sure_you_want_to_archive_this_invoice'))">
                                  {{ $t("archive") }}</b-button> -->
                            </b-row>
                        </b-col>
                        <b-col align="end" md="6">
                            <b-row align="end" class="mt-1 justify-content-end">
                                <img height="50" src="/new-loader.svg" v-if="disabledOffer" width="50"/>

                                <b-button :disabled="item.status == '3' ||
                  disabledOffer
                  " @click="getOffer()" class="mr-2 mb-1" type="button" v-if="item.status == '0'" variant="primary">{{
                                    $t("get_offer") }}
                                </b-button>
                                <b-button :disabled="item && item.scorings && item.scorings.length > 0" @click="scoreInvoice()"
                                          class="mr-2 mb-1" type="submit" v-if="item.status == '3'" variant="primary">{{
                                    $t("score_invoice") }}
                                </b-button>
                                <!-- <b-button
                                  v-if="item.status == '6' || item.status == '9' "
                                  variant="primary"
                                  @click="sellInvoice()"
                                  class="mr-2 mb-1"
                                  >{{ $t("sell_invoice") }}
                                </b-button> -->
                                <img height="50" src="/new-loader.svg" v-if="disableSell" width="50"/>
                                <b-button :disabled="disableSell || !bankDetailsFilled" @click="sellInvoice2()" class="mr-2 mb-1" v-if="(allFieldsFilled ||
                    (item.status != '0' &&
                      item.status != '1' &&
                      item.status != '2' &&
                      item.status != '7' &&
                      item.status != '6')) &&
                  item.scorings &&
                  item.scorings.length > 0
                  " variant="success">
                                    {{
                                    $t("sell_invoice") }}
                                </b-button>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
            <div @click="readInvoice(item)" class="col-md-12 mt-2">
                <b-col class="blue-jumbotron mt-1 pt-2 pb-2">
                    <b-row class="justify-content-between col-md-12 mb-0">
                        <b-col class="">
                            <h6>{{ $t("invoice") }}:</h6>
                        </b-col>
                    </b-row>

                    <b-col>
                        <div class="row col-md-12">
                            <h5 class="mr-2 d-flex col-12">
                                <b-link class="">
                                    <feather-icon class="pointer web" icon="BookOpenIcon" size="22"/>
                                </b-link>
                                <b-link class="col-12">
                                    <feather-icon @click="readInvoice(item)" class="pointer mobile" icon="BookOpenIcon" size="22"/>
                                    {{ item.title }}
                                </b-link>
                            </h5>
                            <div class="col-12">
                                {{ item.created_at | formatDate }}
                            </div>
                        </div>
                    </b-col>

                    <div class="col-md-12 mt-2" v-if="item.associate_files && item.is_reverse != 1">
                        <h6>{{ $t("associated_files") }}</h6>
                        <div :key="index" class="mt-1 row col-md-12" v-for="(item, index) in item.associate_files">
                            <h5 class="mr-2">
                                <b-link>
                                    <feather-icon @click="readPdf(item)" class="pointer web" icon="BookOpenIcon" size="22"/>
                                </b-link>
                                <b-link>
                                    <feather-icon @click="downloadFile(item)" class="pointer mobile" icon="BookOpenIcon"
                                                  size="22"/>
                                </b-link>
                                {{ returnTitle(item.title) }}
                            </h5>
                            <div class="ml-xl-10">
                                {{ item.created_at | formatDate }}
                            </div>
                        </div>
                    </div>
                </b-col>
            </div>
        </div>
        <b-modal scrollable size="md" style="margin-top: 20vh !important;" v-model="levelErrorModal">
            <template #modal-footer="{ }" class="p-0 m-0">
                <p class="d-none">-</p>
            </template>
            <div>
                <div class="col-12 text-center pb-0 mb-0">
                    <feather-icon icon="InfoIcon" size="40" variant="danger"/>
                </div>
                <span class="d-flex p-1 pt-0 mt-0" v-if="levelErrorData">
          <span v-if="$store.state.app.language == 'en'">
            The total value of your active invoices awaiting debtor confirmation or already offered for sale on the
            marketplace or already
            sold but not yet finalised exceeds the permitted trading limit of <strong>{{ levelErrorData.level_total |
              formatAmount }}</strong>
            of your status <strong>{{ levelErrorData.company_level }}</strong>, in which you are currently still located.
            As soon as you have finally settled invoices with a total value of <strong>{{ levelErrorData.level_total |
              formatAmount }} </strong>
            via Aequitex, you will move up to level <strong>{{ levelErrorData.company_next_level }}</strong> and can
            <strong>{{ levelErrorData.next_level_total | formatAmount }}</strong>.
          </span>
          <span v-else-if="$store.state.app.language == 'de'">
            Der Gesamtwert Ihrer aktiven Rechnungen, die auf eine Schuldner-Bestätigung warten oder bereits auf dem
            Marktplatz zum
            Verkauf angeboten werden oder aber bereits verkauft, jedoch noch nicht final abgewickelt wurden, übersteigt
            das
            zulässigen Handelslimit von <strong>{{ levelErrorData.level_total | formatAmount }}</strong> Ihres Status
            <strong>{{ levelErrorData.company_level }}</strong>,
            in dem Sie sich aktuell noch befinden. Sobald Sie Rechnungen im Gesamtwert von
            <strong>{{ levelErrorData.level_total | formatAmount }}</strong>
            über Aequitex final abgewickelt haben, steigen Sie in level
            <strong>{{ levelErrorData.company_next_level }}</strong> und können
            <strong>{{ levelErrorData.next_level_total | formatAmount }}</strong>.
          </span>
        </span>
            </div>
        </b-modal>
        <InformativeModal :debtorConnected="debtorConnected" :disableAccept="disableAccept" :informativeModal="informativeModal"
                          :item="item" :myComp="myComp" :settingsInfo="settingsInfo" @cancel="cancel"
                          @saveSelling="saveSelling">
        </InformativeModal>

        <AddNewCompnayModal :company="companyZefixData" :invoice="invoice" :showDialog="addNewComp"
                            @closeAddCompModal="closeAddCompModal" @showSettings="showSettings"></AddNewCompnayModal>

        <MainContactModal :company="companyZefixDataMainContact" :invoice="invoice" :showDialog="MainContact"
                          @closeMainContactModal="closeMainContactModal" @showSettings="showSettings"></MainContactModal>
        <ApproveModal :textContent="modalText" :title="modalTitle"/>
    </b-card>
</template>
<script>
  import {ValidationObserver, ValidationProvider} from "vee-validate";
  import {email, required} from "@validations";
  import fileDownload from "js-file-download";
  import AddNewCompnayModal from "../cookpit/AddNewCompanyModal.vue";
  import MainContactModal from "./MainContactModal.vue";
  import ApproveModal from "@/components/common/ApproveModal";
  import InformativeModal from "@/components/common/InvoiceDetailsEmail.vue";
  import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal";
  import InvoiceStatus from "@/components/invoices/InvoiceStatus";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import i18n from "@/libs/i18n";
  import {VueAutosuggest} from "vue-autosuggest";

  export default {
    data() {
      return {
        acceptReverse: false,
        levelErrorModal: false,
        levelErrorData: null,
        disableAccept: false,
        disableSell: false,
        silent_factoring_accepted: true,
        resLoader: false,
        disabledOffer: false,
        acceptRecourse: false,
        validSubmit: false,
        isValid: false,
        valid: false,
        canSell: false,
        canScore: false,
        updatedInvoice: {},
        filteredOptions: [],
        company: this.$store.state.app.company.value,
        settings: {
          recourse: "",
          none_recourse: "",
          flash_sale: "",
          open_factoring: "",
          silent_factoring: "",
        },
        // recourse: null,
        agreeTerms: "",
        load: true,
        addNewComp: false,
        uploadFileModal: false,
        associated: null,
        disabledFields: false,
        silentFactoring: false,
        MainContact: false,
        companyZefixDataMainContact: null,
        uploadedInvoce: {
          file: null,
          id: "",
        },
        fileType: "",
        fileDesc: "",
        item: null,
        options: [
          {
            value: 0,
            name: i18n.t("Non-Recourse"),
            title: "non_recourse_tooltip_title",
          },
          {
            value: 1,
            name: i18n.t("Recourse"),
            title: "recourse_tooltip_title",
          },
          {
            value: 2,
            name: i18n.t("Reverse"),
            title: "",
          },
        ],
        acceptReverseError: false,
        companies: [],
        approveModalAction: null,
        modalText: "",
        modalTitle: "",
        auditTrial: false,
        formatAmount: "",
        type: "",
        base64: "",
        showPdfViwer: false,
        showHint: false,
        companyMessage: "",
        myComp: null,
        companyZefixData: null,
        index: "",
        hasOwnership: false,
        saveDebtor: "",
        informativeModal: false,
        settingsInfo: {},
        loader: false,
        debtorConnected: null,
        // business_analysis: null,
        // business_analysisUploaded: false,
        // business_analysis_req_error: false,
        annual_balance: null,
        annual_balanceUploaded: false,
        annual_balance_req_error: false,
        tax_settlement: null,
        tax_settlementUploaded: false,
        tax_settlement_req_error: false,
        errorDueDate: "",
        companyBankData: {},
        currencies: null,
        currency_name: null,
        currency_id: null,
        currencyError: null,
        global_settings: null,
      };
    },
    components: {
      InvoiceStatus,
      StatisticCardHorizontal,
      ValidationProvider,
      ValidationObserver,
      ApproveModal,
      VueAutosuggest,
      MainContactModal,
      AddNewCompnayModal,
      InformativeModal,
    },
    props: ["invoice"],
    created() {
      this.getInvoice();
      this.getSettings();
      this.getCompanies();
      this.getStatistics();
      this.getSetings();
      this.getGlobalSetting();
      // this.getCurrencies();
    },
    computed: {
      allFieldsFilled() {
        return (
            this.item.company_name &&
            this.item.amount &&
            this.item.issue_date &&
            this.item.terms &&
            this.item.due_date &&
            this.item.invoice_number &&
            ((this.item.recourse == 1 && this.acceptRecourse) || (this.item.recourse == 2 && this.acceptReverse) ||
                this.item.recourse == 0)
        );
      },
      bankDetailsFilled() {
        if (this.item.recourse == 2) {
          return (
              this.item.bic &&
              this.item.iban &&
              this.item.bank_address &&
              this.item.bank_name
          );
        }
        return true;
      },
    },
    methods: {

      async getGlobalSetting() {
        await this.$http.post("/global_settings").then((res) => {
          if (res) {
            if(res.data.value) {
              this.global_settings = res.data.value;
              const get_offer_later = this.global_settings.filter(item => item.type === 'get_offer_later');
              this.global_settings = get_offer_later
            }
          }
        })
      },

      // getCurrencies() {
      //   this.$http.post("/currency").then((response) => {
      //     if (response.data.status == 200) {
      //       this.currencies = response.data.value;
      //     }
      //   });
      // },
      //
      // changeCurrency(id, name) {
      //
      //   if(id) {
      //     this.currency_id = id;
      //   }
      //
      //   if(name) {
      //     this.currency_name = name;
      //   }
      //
      // },

      openLink() {
        window.open("https://support-aequitex.zohobookings.eu/#/aequitex/booknow", "_blank");
      },
      getTooltip(option) {
        return option && option.tooltip ? option.tooltip : "";
      },
      returnTitle(title) {
        return this.$t(title);
      },
      acceptReverseChange(val) {
        if (val) {
          this.acceptReverseError = false;
        }
      },
      async saveSelling() {
        this.disableAccept = true;
        try {
          const params = {};
          if (this.item.recourse == 2) {
            this.companyBankData.bic = this.item.bic;
            this.companyBankData.iban = this.item.iban;
            this.companyBankData.bank_name = this.item.bank_name;
            this.companyBankData.bank_address = this.item.bank_address;
            params.company_bank_data = JSON.stringify(this.companyBankData);
          }

          const res = await this.$http.post(
              `/invoice/accept/${this.$route.params.id}`,
              params
          );

          if (res) {
            this.disableAccept = false;
            this.disableSell = false;
            if (res.data.status == 200) {
              this.showSuccessMessage(i18n.t("success"));
              this.getInvoice();
              this.informativeModal = false;
            } else if (res.data.status == 500) {
              this.levelErrorModal = true;
              this.levelErrorData = res.data.value;
            } else {
              this.$swal({
                position: "center",
                icon: "error",
                showCancelButton: true,
                title: this.$t(res.data.message),
                showConfirmButton: true,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
            this.informativeModal = false;
          }
        } catch (error) {
          console.log("error", error);
          this.disableAccept = false;
          this.disableSell = false;
        }
      },
      cancel() {
        this.informativeModal = false;
      },
      sellInvoice2() {
        this.disableSell = true;
        if (this.item.status == 0) {
          this.editInvoice(true);
        }
        if (this.item.recourse == 1) {
          this.saveSelling();
        } else {
          this.informativeModal = true;
          this.disableSell = false;
        }
      },
      sellInvoice() {
        this.$http
            .post(`invoice/readyForSale`, {id: this.$route.params.id})
            .catch((error) => {
              console.log("error", error);
            })
            .then((res) => {
              if (res) {
                if (res.data.status == 200) {
                  this.showSuccessMessage(
                      i18n.t("invoice_is_on_marketplace"),
                      "sold"
                  );
                } else if (res.data.status == 404 || res.data.status == 500) {
                  this.$swal({
                    position: "center",
                    icon: "error",
                    showCancelButton: true,
                    title: this.$t(res.data.message),
                    showConfirmButton: true,
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                }
              }
            });
      },
      scoreInvoice() {
        this.updatedInvoice = this.item;
        let data = {
          invoice_id: this.$route.params.id,
          // debtor_point: this.updatedInvoice.issuer_id,
          // seller_point: this.updatedInvoice.issue_date,
          due_date: this.updatedInvoice.due_date,
          terms: this.updatedInvoice.terms,
          amount: this.updatedInvoice.amount,
        };
        this.$refs.formValidate.validate().then((success) => {
          if (success) {
            this.$http
                .post(`invoice/scoring`, data)
                .catch((error) => {
                  console.log("error", error);
                })
                .then((res) => {
                  if (res) {
                    if (res.data.status == 200) {
                      this.showSuccessMessage(
                          i18n.t("invoice_scored_successfully")
                      );
                      this.getInvoice();
                      this.canSell = true;
                    } else if (res.data.status == 500) {
                      this.$swal({
                        position: "center",
                        icon: "error",
                        title: this.$t("debtor_not_trustable"),
                        showConfirmButton: false,
                        timer: 1500,
                        customClass: {
                          confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                      });
                    }
                  }
                });
          } else {
            this.notValid = false;
          }
        });
      },
      closeAddCompModal(data) {
        this.saveDebtor = data ? data[0].id : "";
        this.$bvModal.hide("AddCompany");
      },
      closeMainContactModal(data) {
        this.saveDebtor = data ? data[0].company_id : "";
        this.$bvModal.hide("MainContact");
      },
      showSettings() {
        this.addNewComp = false;
        this.openModal = false;
      },
      getStatistics() {
        this.$http
            .post(`/company/show/${this.$store.state.auth.user.company_id}`)
            .then((res) => {
              if (res) {
                this.myComp = res.data.value;
                this.$store.state.auth.user.company.level = res.data.value.level;
              }
            });
      },
      getSetings() {
        this.$http.post(`/settings/getMarket`).then((res) => {
          if (res) {
            this.settingsInfo = res.data.value;
          }
        });
      },
      async onSelected(val, item, index) {
        this.loader = true;
        item.company_name = val.item;
        await this.getCompanyDataFromZefix(item, index);
      },
      async getCompanyDataFromZefix(item, index) {
        if (typeof this.companies[item.company_name] != "undefined") {
          try {
            const res = await this.$http.post("/get-company-data-from-zefix", {
              search: this.companies[item.company_name],
            });

            if (res) {
              item.company_uid = res.data.value.uid;
              let data = {
                search: item.company_uid,
                invoice_id: item.id,
              };

              this.addNewComp = false;
              this.MainContact = false;

              const resInvoice = await this.$http.post(
                  "/invoice/getCompany",
                  data
              );

              if (resInvoice) {
                this.loader = false;
                if (
                    resInvoice.data.value &&
                    resInvoice.data.value.from == "Database"
                ) {
                  this.saveDebtor = resInvoice.data.value.company.id;
                  item.company_name = resInvoice.data.value.company.name;
                } else if (
                    resInvoice.data.value &&
                    resInvoice.data.value.from == "ContactPerson"
                ) {
                  this.showMainContactModal(
                      item,
                      index,
                      resInvoice.data.value.company
                  );
                } else {
                  this.showModal(item, index, resInvoice.data.value.company);
                }
              }
            }
          } catch (error) {
            if (error.response && error.response.status === 0) {
              this.$swal({
                position: "center",
                icon: "error",
                title: 'Failed to load data due to CORS issue. Click again to retry.',
                showConfirmButton: true,
                showCancelButton: true,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
            console.error("Error:", error);
          }
        }
      },
      showMainContactModal(item, index, company) {
        this.addNewComp = false;
        this.MainContact = true;
        this.companyZefixDataMainContact = company;
        this.invoice = item;
        this.selectedIndex = index;
        this.$bvModal.show("MainContact");
      },
      showModal(item, index, company) {
        this.addNewComp = true;
        this.MainContact = false;
        this.companyZefixData = company;
        this.invoice = item;
        this.selectedIndex = index;
        this.$bvModal.show("AddCompany");
      },
      async searchForCompaniesName(item) {
        clearTimeout(this.timeout);
        clearTimeout(this.timeout);
        this.companyMessage = "";
        const self = this;
        this.timeout = setTimeout(async function () {
          if (item.company_name.length > 2) {
            self.showHint = true;
            self.companies = [];
            self.filteredOptions = [];

            try {
              const res = await self.$http.post(
                  "/get-companies-name-from-zefix",
                  {
                    search: item.company_name,
                  }
              );

              let obj = res.data.value;
              let myArray = [];

              for (var prop in obj) {
                if (obj[prop] != self.myComp.uid) {
                  myArray.push(prop);
                }
              }

              self.successMessage = false;
              self.showHint = false;
              self.filteredOptions.push({
                data: myArray,
              });
              self.companies = res.data.value;

              if (res.data.value.length < 1) {
                self.companyMessage = true;
              }
            } catch (error) {
              console.error("Error:", error);
              if (error.response && error.response.status === 0) {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: 'Failed to load data due to CORS issue. Click again to retry.',
                  showConfirmButton: true,
                  showCancelButton: true,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
            }
          } else {
            self.companies = [];
            self.showHint = false;
            self.filteredOptions = [];
          }
        }, 1000);
      },
      declineForceUpdate() {
        this.$http
            .post(`/invoice/forceUpdate/${this.item.id}/decline`)
            .then((res) => {
              if (res.data.status == 200) {
                this.item = res.data.value;
              }
            });
      },
      acceptForceUpdate() {
        this.$http
            .post(`/invoice/forceUpdate/${this.item.id}/accept`)
            .then((res) => {
              if (res) {
                if (res.data.status == 200) {
                  this.item = res.data.value;
                }
              }
            });
      },
      readPdf(item) {
        this.$store.commit("app/ON_SIDEBAR_CLICK", false);
        this.$http
            .post("/invoice/admin/associate/read/" + item.id, {
              responseType: "json",
            })
            .then((response) => {
              this.type = "";
              this.base64 = "";
              this.base64 = response.data.value.b64;
              this.type = response.data.value.ext;
              this.$store.commit("app/SET_FILE_TYPE", this.type);
              this.$store.commit("app/SET_FILE_BASE64", this.base64);
              window.open("/read-files", "_blank");
            })
            .catch((error) => {
              this.isLoading = false;
            });
      },
      readInvoice(item) {
        this.$http
            .post("/invoice/download/" + item.id, {}, {responseType: "blob"})
            .then((response) => {
              fileDownload(response.data, item.title);
            })
            .catch((error) => {
            });
      },
      parsedAmount(val) {
        setTimeout(() => {
          if (val) {
            this.formatAmount = Number(this.item.amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&'");
            document.getElementById("amount-input").value = this.formatAmount;
          }
        }, 1000);
      },
      validateUploadedFile(type) {
        return this[`${type}Uploaded`] || this.item.user_uploaded_analysis[type].name;
      },

      changeInvoiceFact(value) {
        // if (
        //   this.item.recourse === 1 &&
        //   (!this.validateUploadedFile('business_analysis') || !this.validateUploadedFile('annual_balance') || !this.validateUploadedFile('tax_settlement'))
        // ) {
        //   this.acceptRecourse = "";
        //   this.$refs.formValidate.validate();
        //   return "";
        // }

        if (this.item.recourse == 1) {
          // if (!this.business_analysis) {
          //   this.business_analysis_req_error = true;
          // }

          if (!this.annual_balance) {
            this.annual_balance_req_error = true
          }

          if (!this.tax_settlement) {
            this.tax_settlement_req_error = true
          }

          // if (this.business_analysis_req_error && this.annual_balance_req_error && this.tax_settlement_req_error) {
          if (this.annual_balance_req_error && this.tax_settlement_req_error) {
            return "";
          }
        }

        this.acceptRecourse = value == true ? true : false;
        let data = {
          data: value ? 1 : 0,
        };
        let type = value == true ? "recourse" : "non-recourse";
        this.$http
            .post(`/invoice/change/${type}/${this.$route.params.id}`, data)
            .then((res) => {
              if (res) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t("success"),
                    icon: "SuccessIcon",
                    variant: "success",
                  },
                });
              }
            });
      },


      // changeInvoiceFact(value,index) {
      //   const item = this.uploadedInvoices[index];
      //   if ((item.business_analysis || this.user_uploaded_analysis.business_analysis) && (item.annual_balance || this.user_uploaded_analysis.annual_balance)        && (item.tax_settlement || this.user_uploaded_analysis.tax_settlement)) {
      //     item.accept_recourse = true;
      //     this.filesMissing = false;
      //   } else {
      //     item.accept_recourse = '';
      //     this.filesMissing = true
      //     return;
      //   }
      // },
      requestProofOfOwnershipPage() {
        this.$router.push(
            "/invoice/" +
            this.$route.params.id +
            "/request/" +
            this.item.invoice_number
        );
      },
      changeTerms(item) {
        if (this.item.issue_date && this.item.due_date) {
          let compareDate = "";
          const startDate = new Date(this.item.issue_date);
          const endDate = new Date(this.item.due_date);
          const differenceInTime = endDate.getTime() - startDate.getTime();
          const differenceInDays = differenceInTime / (1000 * 3600 * 24);
          this.item.terms = Math.abs(Math.round(differenceInDays));
        }
      },
      changeDueDate(item) {
        this.errorDueDate = "";
        if (Number(item.terms) > 120) {
          this.errorDueDate = this.$t("max_allowed_days_is_120");
          return;
        }
        let newDate = new Date();
        if (this.item.issue_date && this.item.terms) {
          let term = Number(this.item.terms);
          let parsed = new Date(this.item.issue_date);
          newDate.setDate(parsed.getDate() + term);
          this.item.due_date = newDate.toISOString().split("T")[0];
        }
      },
      getOffer() {
        this.resLoader = true;
        this.disabledOffer = true;
        this.editInvoice();
      },
      async editInvoice(isFromSell = false) {
        this.updatedInvoice = this.item;
        let data = new FormData();
        data.append(
            "debtor_id",
            this.saveDebtor ? this.saveDebtor : this.updatedInvoice.company.id
        );
        data.append("issue_date", this.updatedInvoice.issue_date);
        data.append("due_date", this.updatedInvoice.due_date);
        data.append("terms", this.updatedInvoice.terms);
        data.append("amount", this.updatedInvoice.amount);
        data.append("invoice_number", this.updatedInvoice.invoice_number);
        data.append("status", this.updatedInvoice.status);
        data.append("recourse", this.updatedInvoice.recourse);
        data.append("accept_recourse", this.acceptRecourse);
        data.append("is_reverse", this.acceptReverse);

        if (this.updatedInvoice.recourse == 1 && !this.acceptRecourse) {
          this.acceptRecourse = "";
          this.$refs.formValidate.validate();
          return "";
        }

        if (
            this.updatedInvoice.recourse == 1 &&
            // (!this.validateUploadedFile('business_analysis') || !this.validateUploadedFile('annual_balance') || !this.validateUploadedFile('tax_settlement'))
            (!this.validateUploadedFile('annual_balance') || !this.validateUploadedFile('tax_settlement'))
        ) {
          this.$refs.formValidate.validate();
          return "";
        }
        if (this.updatedInvoice.recourse == 1) {
          // data.append("business_analysis", this.business_analysis);
          data.append("annual_balance", this.annual_balance);
          data.append("tax_settlement", this.tax_settlement);
        }
        if (this.errorDueDate.length > 1) {
          this.$refs.formValidate.validate();
          this.resLoader = false;
          this.disabledOffer = false;
          return "";
        }

        let showErrorModal = false;
        try {
          const success = await this.$refs.formValidate.validate();
          this.currencyError = '';
          // if(!this.currency_id) {
          //   this.currencyError = 'The Currency 0 field is required';
          //   return;
          // }

          // data.append("currency_id", this.currency_id);

          if (success) {
            if (this.item.recourse == 2 && !this.acceptReverse) {
              this.acceptReverseError = true;
              this.notValid = true;
              showErrorModal = true;
            } else {
              this.companyBankData.bic = this.item.bic;
              this.companyBankData.iban = this.item.iban;
              this.companyBankData.bank_name = this.item.bank_name;
              this.companyBankData.bank_address = this.item.bank_address;
              data.append('company_bank_data', JSON.stringify(this.companyBankData));
              this.disableSell = false;
              this.notValid = false;
              this.disabledOffer = false;
              let config = {headers: {"Content-Type": "multipart/form-data"}};

              const res = await this.$http.post(`/invoice/update/${this.$route.params.id}`, data, config);

              if (res.data.status != 200) {
                showErrorModal = true;
                await this.$swal({
                  position: "center",
                  icon: "error",
                  title: this.$t(res.data.message),
                  showConfirmButton: false,
                  showCancelButton: true,
                  cancelButtonText: "OK!",
                  customClass: {
                    cancelButton: "btn btn-outline-secondary",
                  },
                  buttonsStyling: false,
                  persistent: true,
                  allowOutsideClick: false,
                });
                if (result.dismiss === "cancel") {
                  // window.location.reload()
                }
              } else {
                this.item = res.data.value;
                this.getInvoice();
                if (!isFromSell) {
                  this.showSuccessMessage(i18n.t("success"));
                }
                this.canScore = true;
              }
            }
          } else {
            this.notValid = false;
            showErrorModal = true;

          }
        } catch (error) {
          console.error("Error:", error);
          this.disableSell = false;
          // Handle error
        } finally {
          if (!showErrorModal) {
            this.getInvoice();
          }
          this.resLoader = false;
          this.disabledOffer = false;
          this.disableSell = false;
        }
      },
      getSettings() {
        this.$http.post(`/company/settings`).then((res) => {
          if (res) {
            this.settings = res.data.value;
          }
        });
      },
      getInvoice() {
        this.$http.post(`/invoice/show/${this.$route.params.id}`).then((res) => {
          if (res) {
            this.item = res.data.value;
            this.companyBankData.bic = this.item.bic;
            this.companyBankData.iban = this.item.iban;
            this.companyBankData.bank_name = this.item.bank_name;
            this.companyBankData.bank_address = this.item.bank_address;
            if (res.data) {
              this.item.recourse = res.data.value.recourse;
              let is_reverse = res.data.value.is_reverse == 1;
              if (is_reverse) {
                this.item.recourse = 2;
              }
              this.acceptRecourse = res.data.value.accept_recourse == 1 ? true : false;
              this.acceptReverse = res.data.value.is_reverse == 1 ? true : false;
            }
            //  else {
            //   console.log('ktu 1 ',res.data.value.recourse)
            //   this.item.recourse = 1;
            // }
            if (
                res.data.value.associate_files &&
                res.data.value.associate_files.length > 0
            ) {
              // this.business_analysisUploaded = true;
              this.annual_balanceUploaded = true;
              this.tax_settlementUploaded = true;
            }
            if (
                res.data.value &&
                res.data.value.debtor &&
                res.data.value.debtor.main_contacts_debtor.length > 0
            ) {
              res.data.value.debtor.main_contacts_debtor.forEach((el) => {
                if (el.seller_id == this.item.company_id) {
                  this.debtorConnected = el;
                }
              });
            }
            this.item.debtor = res.data.value.debtor
                ? res.data.value.debtor
                : {name: ""};
            if (!res.data.value.issuer_id) {
              this.canScore = false;
            } else {
              this.canScore = true;
            }
            this.updatedInvoice = res.data.value;
            this.item.silent = this.item.silent ? true : false;
            this.item.flash_sale = this.item.flash_sale ? true : false;
            if (this.item) {
              if (this.item.associate_files.length > 0) {
                const hasProofType = this.item.associate_files.some(
                    (item) =>
                        item.hasOwnProperty("type") &&
                        item.type === "Proof Of Ownership"
                );
                if (hasProofType) {
                  this.hasOwnership = true;
                } else {
                  this.hasOwnership = false;
                }
              }

              if (this.item.flash_sale == 0) {
                this.flashSale = false;
              } else {
                this.flashSale = true;
              }
              if (this.item.silent == 0) {
                this.silentFactoring = 0;
              } else {
                this.silentFactoring = 1;
              }
              // if (this.item.amount != 0.000 || this.invoice_number) {
              //   this.disabledFields = false;
              // } else {
              //   this.disabledFields = true;
              // }

              if (this.item.amount == 0.0) {
                this.item.amount = null;
                this.parsedAmount(this.item.amount);
              }

              this.load = false;
              this.getCompanies();
              if (this.item.flash_sale == 0) {
              } else {
                this.flashSale = true;
              }
            }
          }
        });
      },
      triggerFileInput() {
        this.$refs.fileInput1.$refs.input.click();
      },
      triggerFileInput2() {
        this.$refs.fileInput2.$refs.input.click();
      },
      triggerFileInput3() {
        this.$refs.fileInput3.$refs.input.click();
      },
      storeData() {
        let formData = new FormData();
        let config = {headers: {"Content-Type": "multipart/form-data"}};
        formData.append("pdf", this.associatedEvent.target.files[0]);
        this.$http
            .post(`/invoice/associate/upload/${this.item.id}`, formData, config)
            .then((res) => {
              if (res) {
                let data = {
                  description: this.fileDesc,
                  proof_of_ownership: this.fileType,
                };
                this.$http
                    .post(`/invoice/storeData/${res.data.value.id}`, data)
                    .then((response) => {
                      if (response) {
                        this.uploadFileModal = false;
                        this.showSuccessMessage("File Upload Successfully");
                        this.associated = null;
                        this.fileType = "";
                        this.fileDesc = "";
                      }
                    });
              }
            });
      },
      uploadFile(event, file) {
        this.fileType = "";
        this.fileDesc = "";
        // if (file == "business_analysis") {
        //   this.business_analysis = event.target.files[0];
        //   this.business_analysisUploaded = true;
        // }
        if (file == "annual_balance") {
          this.annual_balance = event.target.files[0];
          this.annual_balanceUploaded = true;
        } else if (file == "tax_settlement") {
          this.tax_settlement = event.target.files[0];
          this.tax_settlementUploaded = true;
        }

        this.associatedEvent = event;
        this.uploadFileModal = true;

      },
      changeModalInfo(action, title, text) {
        this.approveModalAction = action;
        this.modalText = text;
        this.modalTitle = title;
      },
      callApproveModalAction() {
        if (this.approveModalAction == "archive") {
          this.changeStatus("archive");
        } else if (this.approveModalAction == "remove") {
          this.changeStatus("remove");
        } else if (this.approveModalAction == "approve") {
          this.changeStatus("approve");
        } else if (this.approveModalAction == "status") {
          this.changeStatus("status");
        }
      },
      showSuccessMessage(message, type) {
        let text = this.$t(message);
        this.$swal({
          position: "center",
          icon: "success",
          title: text,
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        this.load = false;
        if (type == "remove" || type == "sold" || type == "edited") {
          this.$router.replace("/invoices");
        }
      },
      downloadFile(item) {
        this.$http
            .post(
                "/invoice/admin/associate/download/" + item.id,
                {},
                {
                  responseType: "blob",
                }
            )
            .then((response) => {
              let parsed = response.data.type.split("/");
              let str = "doc." + parsed[1];
              fileDownload(response.data, item.title);
            })
            .catch((error) => {
            });
      },
      changeStatus(type) {
        let status = 0;
        let message = "";
        let route = "";
        if (type == "status") {
          if (this.item.status == 1) {
            status = 0;
          } else {
            status = 1;
          }
          message = i18n.t("status_changed_successfully");
        }
        if (type == "archive") {
          status = 2;
          message = i18n.t("invoice_has_been_archived_successfully");
        }
        if (type == "remove") {
          status = 99;
          route = "remove";
          message = i18n.t("invoice_has_been_removed_successfully");
        }
        if (type == "approve") {
          status = 1;
          message = i18n.t("invoice_has_been_approved_for_sale");
        }
        // 0: inactive
        // 1: active ready for sale
        // 2: archive
        // 99: remove
        this.$http
            .post(`/invoice/changeStatus/${this.$route.params.id}`, {
              status: status,
            })
            .then((res) => {
              if (res) {
                this.showSuccessMessage(message, route);
              }
            });
      },
      removeInvoice() {
        this.$http
            .post(`/invoice/remove/${this.$route.params.id}`)
            .then((res) => {
              if (res) {
                this.showSuccessMessage(i18n.t("removed_successfully"));
              }
            });
      },
      getCompanies() {
        this.$http
            .post("/company/admin/list?")
            .then((response) => {
              if (response.data.status == 200) {
                this.addNewComp = false;
                this.companies = response.data.value.data;
                this.companies.forEach((el) => {
                  if (el.id > el.id + 1) {
                    this.selected = el;
                  }
                  if (el.uid == this.myComp.uid) {
                    el.disabled = true;
                  }
                });
              }
            })
            .catch((error) => {
            });
      },
    },
  };
</script>
<style>
    .red-jumbotron {
        background: rgb(246, 176, 176);
    }

    .blue-jumbotron {
        background: rgba(240, 249, 245, 1);
    }

    .green-jumbotron {
        background: rgb(216, 255, 192);
    }

    .pointer {
        cursor: pointer;
    }

    .text-decoration-underline {
        text-decoration: underline;
    }
</style>

<template>
    <div>
     <b-modal
    v-model="informativeModal"
     scrollable
     no-close-on-backdrop
     hide-header-close
     size="lg"
   >
     <template #modal-footer="{}">
       <b-button size="sm" variant="outline-primary" @click="cancel()">
         {{ $t("back") }}
       </b-button>
       <b-button size="sm" variant="primary" :disabled="disableAccept" @click="saveSelling()">
         {{ $t("accept") }}
       </b-button>
       <img
                  v-if="disableAccept"
                  width="50"
                  height="50"
                  src="/new-loader.svg"
                />
     </template>
       <div v-if="item.is_reverse != 1">
        <div v-if="$store.state.app.language == 'en' && myComp" class="">
        <span style="font-size: 14pt;"> <strong >For your information, {{ myComp.name }}</strong> </span><br>
        <span>  We will reach out to your debtor to verify this invoice and get it's confirmation. Please press <strong>"Accept"</strong> , if this is fine for you.
        </span>
        <br>
        <br>
        <b-card>
          <span>Dear Sir/Madam,</span>
        <br>
        <br>
        I confirm that I have received and verified invoice {{item.invoice_number}} dated {{ item.issue_date }} with an amount of {{ item.amount | formatAmount }} by {{myComp.name }} for the delivered service.
        <br>
        <br>
        I acknowledge that you, Aequitex AG, is entrusted by the {{ myComp.name  }} to collect the claim in the undisputed amount of {{ item.amount | formatAmount }} and I will make the payment to the following account:
        <br>
        <br>
        Account Holder: <strong>Aequitex AG</strong>
        <br>
        IBAN: <strong> {{ settingsInfo.iban }} </strong>
        <br>
        BIC/SWIFT: <strong> {{ settingsInfo.bic }} </strong>
        <br>
        Payment Reference: <strong> {{ item.invoice_number }} </strong>

        <br>
        <br>
        With this confirmation, I acknowledge that my debtor obligations regarding the said invoice will be fulfilled by making payment to <strong> Aequitex AG</strong> and that I have no objections to the invoice or the assignment of the claim to <strong>Aequitex AG</strong>.
        <br>
        <br>
        <br>
        Sincerely,
        <br>
        <strong v-if="debtorConnected" style="font-size: 14pt;">{{ debtorConnected.first_name }} {{ debtorConnected.last_name }}, </strong><br><strong>{{ item.debtor.name }} </strong>
        </b-card>
        </div>
        <div v-else-if="$store.state.app.language == 'de' && myComp">
      <span style="font-size: 14pt;"> <strong>Für Ihre Information, {{ myComp.name  }}</strong> </span><br>
         <span>
           Wir werden uns mit Ihrem Schuldner in Verbindung setzen, um diese Rechnung durch Ihn verifizieren zu lassen. Bitte klicken Sie auf <strong>"Akzeptieren"</strong> , wenn dies für Sie in Ordnung ist.
    </span>
    <br> 
      <br>
      <b-card>
        <span>Sehr geehrte Damen und Herren,</span> 
      <br>
      <br>
      Ich bestätige, dass ich die {{item.invoice_number}} vom {{item.issue_date}} mit einem Betrag von {{ item.amount | formatAmount }} vom {{myComp.name }} für die erbrachten Leistungen erhalten und geprüft habe.
      <br>
      <br>
      Ich nehme zur Kenntnis, dass Sie, die Aequitex AG, vom {{ myComp.name  }} mit dem Einzug der Forderung in Höhe des unbestrittenen Betrages von {{ item.amount | formatAmount }} beauftragt wurden und werde die Zahlung auf das folgende Konto vornehmen:
       <br>
      <br>
      Kontoinhaber: <strong>Aequitex AG</strong> 
      <br>
      IBAN: <strong> {{ settingsInfo.iban }} </strong>
      <br>
      BIC/SWIFT: <strong> {{ settingsInfo.bic }} </strong>
      <br>
      Verwendungszweck: <strong> {{ item.invoice_number }} </strong>
      <br>
      <br>
      Ich anerkenne hiermit die Richtigkeit der vorstehenden Forderung in Höhe von {{ item.amount | formatAmount }} und nehme zur Kenntnis, dass ich durch Zahlung des ausstehenden Betrages von {{ item.amount | formatAmount }} an Sie, die <strong>Aequitex AG</strong>, meinen Zahlungsverpflichtungen aus dieser Rechnung abschliessend nachkomme.
      <br>
      <br>
      Mit freundlichen Grüßen,,
      <br>
      <strong v-if="debtorConnected" style="font-size: 14pt;">{{ debtorConnected.first_name }} {{ debtorConnected.last_name }}, </strong><br><strong>{{ item.debtor.name }} </strong>
    </b-card> </div>
       </div>
       <div v-else>
         <div v-if="$store.state.app.language == 'en' && myComp" class="">
           <span style="font-size: 14pt;"> <strong >For your information, {{ item.debtor.name }}</strong> </span><br>
           <span> We will reach out to your supplier to verify this invoice and get it’s confirmation. Please press <strong>"Accept"</strong> , if you want to proceed with the selling-process.
        </span>
           <br>
           <br>
           <b-card>
             <span>Dear Sir/Madam,</span>
             <br>
             <br>
             We, <strong>{{ item.debtor.name }}</strong>, confirm that the invoice {{item.invoice_number}} was issued to <strong>{{ myComp.name }}</strong> dated {{ item.issue_date }} in the amount of {{ item.amount | formatAmount }} with a payment term until {{ item.due_date }}.
             <br>
             <br>
             As of today's date, we have not received any payment. We agree that Aequitex AG may settle the liability to us, {{item.debtor.name  }}, in the amount of {{ item.amount | formatAmount }} by way of reverse factoring on account of performance.
             <br>
             <br>
             We acknowledge that this does not constitute an acknowledgment of debt or accession to debt by Aequitex AG.
             <br>
             <br>
             Account Holder: <strong>{{ item.debtor.name }}</strong>
             <br>
             IBAN: <strong> {{ item.iban }} </strong>
             <br>
             BIC/SWIFT: <strong> {{ item.bic }} </strong>
             <br>
             Payment Reference: <strong> {{ item.invoice_number }} </strong>
             <br>
             <br>
             <br>
             Sincerely,
             <br>
             <strong v-if="debtorConnected" style="font-size: 14pt;">{{ debtorConnected.first_name }} {{ debtorConnected.last_name }}, </strong><br><strong>{{ item.debtor.name }} </strong>
           </b-card>
         </div>
         <div v-else-if="$store.state.app.language == 'de' && myComp">
           <span style="font-size: 14pt;"> <strong>Für Ihre Information, {{ item.debtor.name  }}</strong> </span><br>
           <span>
          Wir werden uns mit Ihrem Lieferanten in Verbindung setzen, um diese Rechnung durch ihn verifizieren zu lassen. Bitte klicken Sie auf <strong>"Akzeptieren"</strong>, um mit dem Verkaufsprozess fortzufahren.</span>
           <br>
           <br>
           <b-card>
             <span>Sehr geehrte Damen und Herren,</span>
             <br>
             <br>
             wir, {{ item.debtor.name }}, bestätigen, dass die Rechnung {{item.invoice_number}} an {{ myComp.name }} vom {{item.issue_date}} in Höhe von {{ item.amount | formatAmount }} mit einem Zahlungsziel bis {{ item.due_date }} gestellt wurde.
             <br>
             <br>
             Mit heutigem Datum haben wie hierauf keine Zahlung erhalten. Wir erklären uns damit einverstanden, dass die Aequitex AG im Rahmen des Reverse Factoring die Verbindlichkeit gegenüber uns,  {{ item.debtor.name  }}, in Höhe von {{ item.amount | formatAmount }} erfüllungshalber ausgleichen kann.
             <br>
             <br>
             Wir nehmen zur Kenntnis, dass dies kein Schuldanerkenntnis oder Schuldbeitritt der Aequitex AG darstellt.
             <br>
             <br>
             Kontoinhaber: <strong><strong>{{ item.debtor.name }}</strong></strong>
             <br>
             IBAN: <strong> {{ item.iban }} </strong>
             <br>
             BIC/SWIFT: <strong> {{ item.bic }} </strong>
             <br>
             Verwendungszweck: <strong> {{ item.invoice_number }} </strong>

             <br>
             <br>
             Mit freundlichen Grüßen,,
             <br>
             <strong v-if="debtorConnected" style="font-size: 14pt;">{{ debtorConnected.first_name }} {{ debtorConnected.last_name }}, </strong><br><strong>{{ item.debtor.name }} </strong>
           </b-card> </div>
       </div>
   </b-modal>    
    </div>
 
</template>
<script>
export default {
  props: {
    informativeModal: Boolean,
    disableAccept: Boolean,
    myComp: Object,
    item: Object,
    debtorConnected: Object,
    settingsInfo: Object,
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    saveSelling() {
      this.$emit('saveSelling');
    },
  },
};
</script>